import React, {useEffect, useRef} from "react"
import {Link} from "gatsby"
import PropTypes from "prop-types"
import { FaUser, FaPhone, FaEnvelope, FaBuilding, FaMapMarkerAlt, FaPenAlt } from 'react-icons/fa'
import {useDispatch, useSelector} from "react-redux"

import {
    memberToForm,
    onChange,
    onChangeByName,
    submitInformation,
    userToForm
} from "../../redux/registration/registrationAction"
import CountriesInput from "../../components/form/countriesInput"
import PhoneInput from "../../components/form/phoneInput"
import ProfessionalInput from "../../components/form/professionalInput"
import HelpUr from "../../components/help/help"
import AlertCategory from "./alertCategory/alertCategory"
import {isDefined} from "../../utils/data"

const About = ({prevTab, nextTab}) => {
    const dispatch      = useDispatch()
    const {information} = useSelector(({registrationReducer}) => registrationReducer)
    const {categories, category, user, member}  = useSelector(({apiReducer}) => apiReducer)
    const localCategories = ["Physician", "Researcher", "Public Health worker", "Student", "Nurse", "Employee", "Other"]
    
    const disabledInput = isDefined(user)
    /*let btnRef = useRef();*/

    useEffect(() => {
        if(user !== null) {
            dispatch(userToForm(user))

             if(localCategories.filter(ctg => ctg !== "Other").indexOf(user.registered.profession_category) === -1) {
                dispatch(onChangeByName("registered_profession_category", user.registered.profession_category))
                dispatch(onChangeByName("professionalCtgSelect", "Other"))
            }
            else {
                dispatch(onChangeByName("registered_profession_category", user.registered.profession_category))
            }
        }
    }, [user])
    var trex ="<b>Union members have discounted registration</b><br/>Alongside a range of benefits Union members receive a €50 discount. New members will receive 15 months membership for the price of 12 (terms apply).<br/>Before completing the form below, <a href='https://www.theunion.org/get-involved/join-the-union'>join The Union</a> or <a href='https://membership.theunion.org/login?cbu=/membership/renewal#subscription'>renew your membership</a>.<br/><br/>Please enter your profile details."
    if(member !== null) {
        var trex = "<b>Thank you for being part of the movement of like-minded individuals and organisations who work together to champion change for a better future in lung health.</b><br/><br/>The following information is drawn from our membership database.<br/>Please check all the details to ensure that they are accurate and up to date."
    }
    if(user !== null) {
        var trex = "<b>Welcome back. You can edit your profil details."
    }
    useEffect(() => {
        if(member !== null) {
            dispatch(memberToForm(member))
        }
    }, [member])

    const onSubmit = (e = null) => {
        e.preventDefault()
        const form = e.currentTarget
        form.myButton.disabled = true;
        form.myButton.value = "Please wait...";

        if (form.checkValidity() === false) {
            form.myButton.disabled = false;
            form.myButton.value = "Submit";
            e.stopPropagation()
        }

      dispatch(submitInformation(user, information, categories, category, nextTab))
    }

    return (
        <div className={"content about"}>
            <p className={"title"}>
                Register here for the Union World Conference
            </p>
            <AlertCategory/>

            
  
        <p className={"important-infos"}>We just need to collect a few details to get you booked in.<br /><i>Note: for a €50 discount, <a href='https://www.theunion.org/get-involved/join-the-union'>Union members</a> should use the same email associated with your membership account.</i></p>

            <form onSubmit={onSubmit} className={"needs-validation"} noValidate>
                <div className="form-group">
                    <div className="form-row">
                        <div className={"col-12 col-md-6 input-group"}>
                            <label><FaUser/></label>
                            <select className="form-control" name={"registered_gender"} value={information.registered_gender.value} onChange={e => dispatch(onChange(e))} required>
                                <option value="" disabled selected>Title *</option>
                                <option>Mr</option>
                                <option>Ms</option>
                                <option>Mx</option>
                                <option>Dr</option>
                                <option>Prof</option>
                            </select>
                            {information.registered_gender.error !== "" ?
                                <div className="invalid-feedback">{information.registered_gender.error}</div>
                            : ""}
                        </div>
                    </div>
                    <div className="form-row">
                        <div className={"col-12 col-md-6 input-group"}>
                            <label><FaUser/></label>

                            <input type="text" className="form-control" placeholder="Firstname *" name={"registered_firstname"} value={information.registered_firstname.value} onChange={e => dispatch(onChange(e))} disabled={disabledInput} required/>
                        {information.registered_firstname.error !== "" ?
                            <div className="invalid-feedback">{information.registered_firstname.error}</div>
                            : ""}
                        </div>
                        <div className={"col-12 col-md-6 input-group"}>
                            <label><FaUser/></label>
                            <input type="text" className="form-control" placeholder="Lastname *" name={"registered_lastname"} value={information.registered_lastname.value} onChange={e => dispatch(onChange(e))} disabled={disabledInput} required/>
                        {information.registered_lastname.error !== "" ?
                            <div className="invalid-feedback">{information.registered_lastname.error}</div>
                        : ""}
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="form-row">
                        <div className={"col-12 col-md-6 input-group"}>
                            <label><FaMapMarkerAlt/></label>
                            <input type="text" className="form-control" placeholder="Address *" name={"registered_adress1"} value={information.registered_adress1.value} onChange={e => dispatch(onChange(e))} required/>
                        {information.registered_adress1.error !== "" ?
                            <div className="invalid-tooltip">{information.registered_adress1.error}</div>
                            : ""}
                        </div>
                        <div className={"col-12 col-md-6 input-group"}>
                            <label><FaMapMarkerAlt/></label>
                            <input type="text" className="form-control" placeholder="Additional address" name={"registered_adress2"} value={information.registered_adress2.value} onChange={e => dispatch(onChange(e))} required={information.registered_adress2.required}/>
                            {information.registered_adress2.error !== "" ?
                                <div className="invalid-tooltip">{information.registered_adress2.error}</div>
                            : ""}
                        </div>
                        <div className={"col-12 col-md-4 col-lg-4 input-group"}>
                            <label><FaMapMarkerAlt/></label>
                            <input type="text" className="form-control" placeholder="Postal Code *" name={"registered_zipcode"} value={information.registered_zipcode.value} onChange={e => dispatch(onChange(e))} required/>
                            {information.registered_zipcode.error !== "" ?
                                <div className="invalid-tooltip">{information.registered_zipcode.error}</div>
                            : ""}
                        </div>
                        <div className={"col-12 col-md-4 col-lg-4 input-group"}>
                            <label><FaMapMarkerAlt/></label>
                            <input type="text" className="form-control" placeholder="City *" name={"registered_city"} value={information.registered_city.value} onChange={e => dispatch(onChange(e))} required/>
                            {information.registered_city.error !== "" ?
                                <div className="invalid-tooltip">{information.registered_city.error}</div>
                            : ""}
                        </div>
                        <div className={"col-12 col-md-4 input-group"}>
                            <label><FaMapMarkerAlt/></label>
                                <CountriesInput value={information.registered_country.value} onChange={e => dispatch(onChange(e))} disabled={disabledInput}/>
                            {information.registered_country.error !== "" ?
                                <div className="invalid-tooltip">{information.registered_country.error}</div>
                            : ""}
                        </div>

                    </div>
                </div>
                            
                <div className="form-group">
                    <div className="form-row">
                        <div className={"col-12 col-md-6 input-group"}>
                            <label><FaEnvelope/></label>
                            <input type="email" className="form-control" placeholder="Email *" name={"registered_mail"} value={information.registered_mail.value} onChange={e => dispatch(onChange(e))} disabled={disabledInput} required/>
                            {information.registered_mail.error !== "" ?
                                <div className="invalid-tooltip">{information.registered_mail.error}</div>
                            : ""}
                        </div>
                        <div className={"col-12 col-md-6 input-group"}>
                            <label><FaEnvelope/></label>
                            <input type="email" className="form-control" placeholder="Confirm Email *" name={"confirmEmail"} value={information.confirmEmail.value} onChange={e => dispatch(onChange(e))} disabled={disabledInput} required/>
                            {information.confirmEmail.error !== "" ?
                                <div className="invalid-tooltip">{information.confirmEmail.error}</div>
                            : ""}
                        </div>
                        <div className={"col-12 col-md-6 input-group"}>
                            <label><FaPhone/></label>
                            <PhoneInput value={information.registered_phone.value} onChange={e => dispatch(onChange(e))}/>
                            {information.registered_phone.error !== "" ?
                                <div className="invalid-tooltip">{information.registered_phone.error}</div>
                            : ""}
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="form-row">
                        <div className={"col-12 col-md-6 input-group"}>
                            <label><FaBuilding/></label>
                            <input type="text" className="form-control" placeholder="Organisation/Institution *" name={"registered_organisation"} value={information.registered_organisation.value} onChange={e => dispatch(onChange(e))} required/>
                            {information.registered_organisation.error !== "" ?
                                <div className="invalid-tooltip">{information.registered_organisation.error}</div>
                            : ""}
                        </div>
                        <div className={"col-12 col-md-6 input-group"}>
                            <label><FaBuilding/></label>
                            <input type="text" className="form-control" placeholder="Department *" name={"registered_department"} value={information.registered_department.value} onChange={e => dispatch(onChange(e))} required/>
                            {information.registered_department.error !== "" ?
                                <div className="invalid-tooltip">{information.registered_department.error}</div>
                            : ""}
                        </div>
                </div>
                <div className="form-row">
                        <div className={"col-12 col-md-6 input-group"}>
                            <label><FaPenAlt/></label>
                            <input type="text" className="form-control" placeholder="Current Position *" name={"registered_function"} value={information.registered_function.value} onChange={e => dispatch(onChange(e))} required/>
                            {information.registered_function.error !== "" ?
                                <div className="invalid-tooltip">{information.registered_function.error}</div>
                            : ""}
                        </div>
                        <ProfessionalInput value={information.professionalCtgSelect.value}
                                           onChange={e => dispatch(onChange(e))}
                                           valueTextInput={information.registered_profession_category.value}
                                           error={information.professionalCtgSelect.error}
                                           disabled={disabledInput}/>
                    </div>
                </div>

                <div className={"content-footer"}>
                    <a className={"sr-btn-next btn btn-secondary"} href={"https://union2020.urevent.fr/login"}>Already registered ? Login</a>
                    <small  className={"help"}><HelpUr/></small>
                    <button type={"submit"} name={"myButton"} value={"Submit"} className={"sr-btn-next btn btn-info"}>Next</button>
                </div>
            </form>
        </div>
    )
}

About.propTypes = {
    prevTab: PropTypes.func.isRequired,
    nextTab: PropTypes.func.isRequired,
}

export default About
