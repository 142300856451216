import React from "react"
import PropTypes from "prop-types"
import {Link} from "gatsby"
import {useDispatch, useSelector} from "react-redux"


import CheckboxInput from "../welcome/checkboxInput"
import {recordUser} from "../../../redux/api/apiAction"
import BankTransferModal from "./bankTransferModal"
import CreditCardModal from "./creditCardModal"
import RefundModal from "./refundModal"
import {isDefined} from "../../../utils/data"
import TermsOfSales from "../../../components/termsOfSales/termsOfSales"
import {onChange} from "../../../redux/registration/registrationAction"

const Payment = ({total}) => {
    const dispatch  = useDispatch()
    const {user}    = useSelector(({apiReducer}) => apiReducer)
    const data      = useSelector(({registrationReducer}) => registrationReducer)
    const {stripe}  = useSelector(({registrationReducer}) => registrationReducer)   
    const {information} = useSelector(({registrationReducer}) => registrationReducer)

    const recordRegistration = (payment = false) => {
        dispatch(recordUser(user, data, payment === true ? 1 : 0))
        
    }

    return (
        <div className={"sr-card summary-block payment"}>
            <div className={"summary-body mb-2"}>
                After paying your registration you will automatically receive a pro forma invoice.<br/>If you require a fiscal invoice please request it by email through: <a className={"whitecolor"} href={"mailto:registration-theunion@urevent.fr"} target={"_blank"}>registration-theunion@urevent.fr</a>

            </div>
        {isDefined(user) ? 
            <div className={"summary-body mb-2"}>
                <a className={"btn btn-sm btn-warning"} href={"https://theunion.urevent.fr/documents/pro_forma/" + user.registered.idregistered} target="_blank">Download Proforma</a>
            </div>
            : ""
        }
<hr/>

        
            <div className={"summary-header mb-4"}>
            {!isDefined(user) ? 
                <CheckboxInput placeholder={" I understand that by creating an account,"}
                   onChange={e => dispatch(onChange(e, true))}
                   name={"registered_cvg"}
                   value={information.registered_cvg.value}
                   required={true}/>
                : ""
            }
                {isDefined(user) ? isDefined(user.registered.registered_payments) ?
                    parseFloat(user.registered.registered_payments) < parseFloat(total) ?
                        <>
                            
                            <div className={"row p-3"}>
                                <button className={"btn btn-warning"}><CreditCardModal stripe={stripe} total={total - (parseFloat(user.registered.registered_payments) || 0)}/></button>
                            </div>
                            <div className={"row "}>
                                    or
                            </div>
                            <div className={"row p-3"}>
                                <Link to={"confirmation-record"}>
                                    <button className={"btn btn-warning mb-2"} onClick={() => recordRegistration()}>Save registration</button>
                                </Link>
                            </div>
                        </>
                        :
                        parseFloat(user.registered.registered_payments) > parseFloat(total) ?
                            <>
                                <div className={"row p-3"}>
                                    <button className={"btn btn-warning"}><RefundModal/></button>
                                </div>
                            <div className={"row"}>
                                    or
                            </div>
                                <div className={"row p-3"}>
                                    <Link to={"confirmation-record"}>
                                        <button className={"btn btn-warning mb-2"} onClick={() => recordRegistration()}>Save registration</button>
                                    </Link>
                                </div>
                            </>
                            : "" : "" :
                    <>
                    {information.registered_cvg.value === true || information.registered_cvg.value === "1" ?
                     <>
                        <div className={"row mt-3 p-3"}>
                            <button className={"btn btn-warning"}><CreditCardModal stripe={stripe} total={total}/></button>
                        </div>
                            <div className={"row"}>
                                    or
                            </div>
                        <div className={"row p-3"}>
                            <Link to={"confirmation-record"}>
                                <button className={"btn btn-warning mb-2"} onClick={() => recordRegistration()}>Save registration</button>
                            </Link>
                        </div>
                    </>
                 : 
                 <>
                                         <div className={"row mt-3 p-3"}>
                            <button className={"btn btn-warning"} disabled={!(information.registered_cvg.value === true || information.registered_cvg.value === "1")}>Card payment</button>
                        </div>
                            <div className={"row"}>
                                    or
                            </div>
                        <div className={"row p-3"}>
                            <Link to={"confirmation-record"}>
                                <button className={"btn btn-warning mb-2"} disabled={!(information.registered_cvg.value === true || information.registered_cvg.value === "1")} >Save registration</button>
                            </Link>
                        </div>
                    </>}
                
                    </>}
            </div>
            <hr/>
            <div className={"summary-footer"}>
                <div className={"row"}>
                    <TermsOfSales />
                </div>
            </div>
        </div>
    )
}

Payment.propTypes = {
    total: PropTypes.string.isRequired,
}

export default Payment
