import React from "react"
import PropTypes from "prop-types"
import {useDispatch, useSelector} from "react-redux"
import { parsePhoneNumberFromString } from 'libphonenumber-js'

import {onChangeByName} from "../../redux/registration/registrationAction"

const PhoneInput = ({value, onChange, name}) => {
    const dispatch = useDispatch()
    const {information} = useSelector(({registrationReducer}) => registrationReducer)

    const onChangeNumber = (e) => {
        onChange(e)
        const phoneNumber = parsePhoneNumberFromString(e.target.value, information.countryCode.value)
        if (phoneNumber) {
            dispatch(onChangeByName(name, phoneNumber.formatInternational()))
        }
    }

    return (
        <input type="phone" className="form-control" placeholder="Phone" name={name} value={value} onChange={e => onChangeNumber(e)}/>
    )
}

PhoneInput.propTypes = {
    value:      PropTypes.string.isRequired,
    onChange:   PropTypes.func.isRequired,
    name:       PropTypes.string,
}

PhoneInput.defaultProps = {
    name: "registered_phone"
}

export default PhoneInput
