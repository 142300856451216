import React, {useEffect, useState} from "react"
import {useSelector} from "react-redux"

import "./alertCategory.scss"
import {isDefined} from "../../../utils/data"

const AlertCategory = () => {
    const {information} = useSelector(({registrationReducer}) => registrationReducer)
    const {category}    = useSelector(({registrationReducer}) => registrationReducer)    
    const {user}  = useSelector(({apiReducer}) => apiReducer)
    

    const condition = isDefined(category) ? category.idcategories === "8" || category.idcategories === "9" || category.idcategories === "16" || category.idcategories === "17" || category.idcategories === "18" || category.idcategories === "21" : false
    const [alertIsVisible, setAlertIsVisible] = useState(condition)

    useEffect(() => {
        setAlertIsVisible(condition)
    }, [information.registered_profession_category, information.registered_under])

    return (
        alertIsVisible ?
            category.idcategories === "8" || category.idcategories === "17" ?
                information.registered_file.value != "" ? 
                   <div className={"alert-category"}>Please note all documents will be validated and any irregularities may result in a registration cancellation without any responsibility for The Union.</div> 
                    : <div className={"alert-category"}>As a student your registration fee is automatically reduced and you will need to provide a copy of your student card or a proof of enrolment in a University programme. 
            </div> 
            : category.idcategories === "9" || category.idcategories === "16" ?
                information.registered_file.value != "" ? 
                   <div className={"alert-category"}>Please note all documents will be validated and any irregularities may result in a registration cancellation without any responsibility for The Union.</div> 
                    : <div className={"alert-category"}>As a Nurse your registration fee is automatically reduced and you will need to provide a letter from your employer confirming you are engaged as a nurse. 
            </div> 
            : ""
        : ""
    )
}

export default AlertCategory
