import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import { FaBuilding } from 'react-icons/fa'

import { onChangeDocuments} from "../../../redux/registration/registrationAction"
import CountriesGeneralInput from "../../../components/form/countriesGeneralInput"

const DifferentInvoiceAdressInput = () => {
    const dispatch      = useDispatch()
    const {documents} = useSelector(({registrationReducer}) => registrationReducer)

    const [visible, setVisible] = useState(false)

    useEffect(() => {
        setVisible(documents.differentInvoiceAdress.value === true)
    }, [documents.differentInvoiceAdress.value])

    const onChange = (e, checkbox = false) => {
        const name  = e.target.name
        const value = checkbox ? e.target.checked : e.target.value

        dispatch(onChangeDocuments(name, value))
    }

    return (
        <div className={"sr-card summary-block your-order"}>
            <div className={"summary-header"}>
                <div className={"form-row"}>
                    <div className={"col-12 mb-3"}>
                        <input type="checkbox" id={"differentInvoiceAdress"} className={"mr-3"} name={"differentInvoiceAdress"} checked={documents.differentInvoiceAdress.value} value={documents.differentInvoiceAdress.value} onChange={e => onChange(e, true)} required/>
                        <label className={"label-checkbox"} htmlFor={"differentInvoiceAdress"}><b>I require a different invoice address</b></label>
                        {documents.differentInvoiceAdress.error !== "" ?
                            <div className="invalid-feedback">{documents.differentInvoiceAdress.error}</div>
                        : ""}
                    </div>
                </div>
            </div>
            <div className={"summary-body"}>
                <div className={"form-row"}>
                    <div className={"col-12 cinput-group"} style={{display: visible ? "flex" : "none"}}>

                        <input type="text" id={"registered_society_name"} className="form-control" name={"registered_society_name"} placeholder={"Invoice Recipient"} value={documents.registered_society_name.value} onChange={e => onChange(e)} required/>
                        {documents.registered_society_name.error !== "" ?
                            <div className="invalid-feedback">{documents.registered_society_name.error}</div>
                        : ""}
                    </div>
                    <div className={"col-12 input-group"} style={{display: visible ? "flex" : "none"}}>
                        <input type="text" id={"registered_society_adress"} className="form-control" name={"registered_society_adress"} placeholder={"Invoice Adress"} value={documents.registered_society_adress.value} onChange={e => onChange(e)} required/>
                        {documents.registered_society_adress.error !== "" ?
                            <div className="invalid-feedback">{documents.registered_society_adress.error}</div>
                        : ""}
                    </div>
                    <div className={"col-12 col-md-6 input-group"} style={{display: visible ? "flex" : "none"}}>
                        <input type="text" id={"registered_society_cp"} className="form-control" name={"registered_society_cp"} placeholder={"Invoice Zip code"} value={documents.registered_society_cp.value} onChange={e => onChange(e)} required/>
                        {documents.registered_society_cp.error !== "" ?
                            <div className="invalid-feedback">{documents.registered_society_cp.error}</div>
                        : ""}
                    </div>
                    <div className={"col-12 col-md-6 input-group"} style={{display: visible ? "flex" : "none"}}>
                        <input type="text" id={"registered_society_city"} className="form-control" name={"registered_society_city"} placeholder={"Invoice City"} value={documents.registered_society_city.value} onChange={e => onChange(e)} required/>
                        {documents.registered_society_city.error !== "" ?
                            <div className="invalid-feedback">{documents.registered_society_city.error}</div>
                            : ""}
                    </div>
                    <div className={"col-12 col-md-6 input-group"} style={{display: visible ? "flex" : "none"}}>
                        <CountriesGeneralInput name={"registered_society_country"}
                                               value={documents.registered_society_country.value}
                                               onChange={e => onChange(e)}
                                               placeholder={"Invoice Country"}
                                               required={false}/>
                        {documents.registered_society_country.error !== "" ?
                            <div className="invalid-feedback">{documents.registered_society_country.error}</div>
                            : ""}
                    </div>
                    <div className={"col-12 col-md-6 input-group"} style={{display: visible ? "flex" : "none"}}>
                        <input type="text" id={"registered_society_vat"} className="form-control" name={"registered_society_vat"} placeholder={"VAT nb"} value={documents.registered_society_vat.value} onChange={e => onChange(e)} required/>
                        {documents.registered_society_vat.error !== "" ?
                            <div className="invalid-feedback">{documents.registered_society_vat.error}</div>
                            : ""}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DifferentInvoiceAdressInput
